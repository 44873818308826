/**
 * Automatic tns (tiny-slider) settings generation for all cmp-articles component. Setting is for all articles carousel the same, just the name of classes and object name differs between each tns instances.
 *
 * @version 3
 * @author Cenek Hybler <hybler@avast.com>
 * @requires cash
 */

(function() {
	'use strict';
	var
		$ = avm.require('cash'),

		//all carousel sliders
		$carousels = $('.carousel .article-items'),

		// Display current slide number / total number of slides (example: 1/6)
		slideCounter = function(slider) {
			var info = slider.getInfo(),
				name = info.container.classList[1],
				sliderEl = document.querySelector('[data-name=' + name + ']'),
				slideCounterEl = sliderEl.querySelector('.tns-liveregion');

			function updateCounter(info) {
				// calculate total slides and active slide
				// info.items is a number of items visible in viewport
				var totalSlides = info.items > 1 ? Math.ceil(info.slideCount / info.items) : info.slideCount,
					activeSlide = info.items > 1 ? Math.ceil(info.displayIndex / info.items) : info.displayIndex;

				slideCounterEl.innerHTML = activeSlide + '/' + totalSlides;
			}

			// Set initial counter value
			updateCounter(info);

			// Update the counter on slide change
			slider.events.on('transitionStart', updateCounter);

			// Update the counter on screen resize (new breakpoint)
			slider.events.on('newBreakpointEnd', updateCounter);
		},

		// tiny slider configuration for given class name
		createSlider = function(className){
			window[className] = tns({
				"container": "."+className,
				"controlsContainer": "."+className+"-controls",
				"preventScrollOnTouch": "force",
				"controlsPosition": "bottom",
				"navPosition": "bottom",
				"loop": false,
				"slideBy": "page",
				"responsive": {
					"576": {
						"items": 2
					},
					"992": {
						"items": 3
					},
					"1366": {
						"items": 4
					}
				},
				onInit: function(){
					$('.'+className+'-controls').attr('tabindex', -1);
					$('.'+className+'-controls [data-controls="next"]').attr('tabindex', 0);
					$('.carousel .tns-nav').attr('tabindex', -1);
					$('.carousel [data-nav]').attr('tabindex', 0);
				}
			});

			// bind function to event and make sure that prev button is no longer unaccessible
			// overrides the onInit function and disabled buttons settings
			window[className].events.on('indexChanged', function() {
				$('.'+className+'-controls [data-controls="prev"]:not([disabled])').attr('tabindex', 0);
			});

			// create Slide counter bellow the slider
			slideCounter(window[className]);
		};

	// detect all sliders and assign each a slider configuration by its class name. This expects that if there is more article carousels on the page, they have unique class (dafault class name is overriden by snippet parameter)
	$carousels.each(function() {
		var
			classesList = $(this).attr('class').split(/\s+/),
			secondClassName = classesList[1];

		createSlider(secondClassName);
	});
})();

// Preload carousel images on mobile view, see GLOWEB-7598
(function () {
	'use strict';

	if (window.innerWidth < 768) {
		// Function to preload carousel images with the class "article-img"
		function preloadCarouselImages() {
			const articleImages = document.querySelectorAll('.article-img');

			articleImages.forEach(function (img) {
				const src = img.getAttribute('src');
				if (src) {
					const image = new Image();
					image.src = src;
				}
			});
		}

		// Run the preloadCarouselImages function when the page loads
		window.addEventListener('load', preloadCarouselImages);
	}
})();
